@import '../../styles/propertySets.css';

/* Animation for the ShareYourRun elements */

.root {
  @apply --backgroundImageSectionShareYourRun;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.shareYourRunContent {
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the ShareYourRunContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.shareYourRunMainTitleInvisible {
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.5s ease-out;
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.shareYourRunMainTitle {
  opacity: 1;
  transform: none;
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.shareYourRunSubTitleInvisible {
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.5s ease-out;
  @apply --marketplaceH2FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.shareYourRunSubTitle {
  opacity: 1;
  transform: none;
  @apply --marketplaceH2FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.buttonsInvisible {
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.5s ease-out;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: var(--SectionHero_desktopTitleMaxWidth);
}

.buttons {
  opacity: 1;
  transform: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: var(--SectionHero_desktopTitleMaxWidth);
}

.browseButtonSection {
  flex-grow: 1;
  margin: 0 12px 0 0;
}

.offerButtonSection {
  flex-grow: 1;
  margin: 0 12px 0 0;
}

.shareYourRunButton {
  @apply --marketplaceButtonStyles;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}
