@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;

  text-align: left;
  margin-top: 0;
  margin-bottom: 18px;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  text-align: left;
  @media (--viewportMedium) {
    width: 50%;
    margin-right: 80px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  font-weight: var(--fontWeightBold);

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
    font-weight: var(--fontWeightBold);
  }
}

.iconInvisible {
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.5s ease-out;
}

.iconVisible {
  opacity: 1;
  transform: none;
}

.getDrink {
  outline: none;
}

.haveDrink {
  display: none;
}

.getDrink:focus + .haveDrink {
  display: block;
  position: relative;
}

.viva {
  font-weight: var(--fontWeightBold);
  position: relative;
}
