@import '../../styles/propertySets.css';

/* Animation for the ShareYourRun elements */

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.shareYourRunContent {
  margin: 0 0 0 0;

  @media (--viewportMedium) {
    margin: 0 0 0 0;
  }

  /* Special viewport for adjusting the ShareYourRunContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}
