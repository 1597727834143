.guestCountInputWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0px;

  & label {
    margin-right: auto;
  }

  & small {
    display: block;
    font-size: 14px;
  }
}

.guestCountInputInfoWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 14px;

  & label {
    margin-right: auto;
  }

  & small {
    display: block;
    font-size: 14px;
  }
}

.guestCountIncrement,
.guestCountDecrement {
  border: 2px solid var(--marketplaceColor);
  border-radius: 100%;
  color: var(--marketplaceColor);
  font-size: 18px;
  line-height: 0;
  height: 20px;
  width: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  cursor: pointer;

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }

  & span {
    display: flex;
    align-items: center;
    height: 10px;
  }

  &:focus {
    outline: none;
  }
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.guestCountNumber {
  font-size: 22px;
  font-weight: var(--fontWeightBold);
  line-height: 24px;
  text-align: center;
  border: 0;
  flex: 0 1 auto;
  padding: 0;
  width: 40px;
}
