@import '../../styles/propertySets.css';

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    left: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    left: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.headingContainer {
  border-radius: 4px;
  height: 100%;
  background-image: url('./contact.jpg');
  background-color: var(--matterColorAnti);
  background-size: cover;
  background-position: center top;
  margin-bottom: 10px;
  @media (--viewportLarge) {
    max-width: 563px;
  }
}

.heading {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColorLight);
  padding: 50px 0 0 50px;
  composes: animation;
  animation-delay: 0.5s;
}

.subHeading {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColorLight);
  padding: 0 0 50px 50px;
  composes: animation;
  animation-delay: 0.65s;
}

.content {
  @media (--viewportLarge) {
    max-width: 563px;
  }
}
