@import '../../styles/propertySets.css';

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    left: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    left: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.headingContainer {
  border-radius: 4px;
  height: 100%;
  background-image: url('./faq.jpg');
  background-color: var(--matterColorAnti);
  background-size: cover;
  background-position: center top;
  margin-bottom: 10px;
  @media (--viewportLarge) {
    max-width: 563px;
  }
}

.heading {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColorLight);
  padding: 50px 0 0 50px;
  composes: animation;
  animation-delay: 0.5s;
}

.subHeading {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColorLight);
  padding: 0 0 50px 50px;
  composes: animation;
  animation-delay: 0.65s;
}

.content {
  @media (--viewportLarge) {
    max-width: 563px;
  }
}

/* Accordion styles */
.tabs {
  overflow: hidden;
}
.tab {
  width: 100%;
  overflow: hidden;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
}
.faqinput {
  height: 0;
  display: block;
  opacity: 0;
  z-index: -1;
}
.tablabel {
  @apply --marketplaceDefaultFontStyles;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: .5em .5em .5em .5em;
  cursor: pointer;
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: rgba(255, 255, 255, 0);
  transition: var(--transitionStyleButton);
}
.tablabel:hover {
  border-left-style: solid;
  border-width: 5px;
  border-color: var(--marketplaceColor);
}
.tablabel::after {
  content: '\FE40';
  color: var(--marketplaceColor);
  width: 1em;
  height: 1em;
  text-align: center;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}
.tabcontent {
  @apply --marketplaceSmallFontStyles;
  background: var(--matterColorLight);
  max-height: 0;
  padding: 0 1em;
  color: var(--matterColor);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: var(--matterColorLight);
}
.tabclose {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 1em;
  cursor: pointer;
}
.tabclose:hover {
}
input:checked + .tablabel {
  border-left-style: solid;
  border-width: 5px;
  border-color: var(--marketplaceColor);
}
input:checked + .tablabel::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
input:checked ~ .tabcontent {
  max-height: 100vh;
  padding: 1em;
}
.list:before {
  content: '\01F45F\a0';
}
.list {
  list-style: none;
  padding-left: 1.7em;
  padding-bottom: 0.7em;
  text-indent: -1.7em;
}
.fail {
  color: var(--failColor);
}
