@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    left: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    left: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.headingContainer {
  border-radius: 4px;
  height: 100%;
  background-image: url('./about.jpg');
  background-color: var(--matterColorAnti);
  background-size: cover;
  background-position: center top;
  margin-bottom: 10px;
  @media (--viewportLarge) {
    max-width: 563px;
  }
}

.headingContainerTwo {
  border-radius: 4px;
  height: 100%;
  background-image: url('./aboutTwo.jpg');
  background-color: var(--matterColorAnti);
  background-size: cover;
  background-position: center top;
  margin-bottom: 10px;
  margin-top: 10px;
  @media (--viewportLarge) {
    max-width: 563px;
  }
}

.headingContainerThree {
  border-radius: 4px;
  height: 100%;
  background-image: url("./aboutThree.jpg");
  background-color: var(--matterColorAnti);
  background-size: cover;
  background-position: center center;
  margin-bottom: 10px;
  margin-top: 10px;
  @media (--viewportLarge) {
    max-width: 563px;
  }
}

.heading {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColorLight);
  padding: 50px 0 0 50px;
  composes: animation;
  animation-delay: 0.5s;
}

.subHeading {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColorLight);
  padding: 0 0 50px 50px;
  composes: animation;
  animation-delay: 0.65s;
}

.content {
  @media (--viewportLarge) {
    max-width: 563px;
  }
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  text-align: left;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    width: 50%;
    margin-right: 80px;
  }
}

.step:last-of-type {
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  font-weight: var(--fontWeightBold);

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
    font-weight: var(--fontWeightBold);
  }
}

.iconInvisible {
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.5s ease-out;
}

.iconVisible {
  opacity: 1;
  transform: none;
}
